import React, { useEffect, useState } from 'react';
import { NftCal, NftGrid, NftTotal } from './styles';
import { useGlobal } from '../../contexts/GlobalContext';

export default function NftCalculator(props) {
  const { invokeServer } = useGlobal();
  const [cost, setCost] = useState([]);
  const [cycle, setCycle] = useState([]);

  const [cost2, setCost2] = useState([]);
  const [cycle2, setCycle2] = useState([]);

  const [cost3, setCost3] = useState([]);
  const [cycle3, setCycle3] = useState([]);

  const [cost4, setCost4] = useState([]);
  const [cycle4, setCycle4] = useState([]);

  const [planData, setPlanData] = useState([]);

  const loadNotification = () => {
    invokeServer('get', `/api/plans/find/1`)
      .then((r) => {
        const plans = r.data.msg;
        setPlanData(plans);
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
  };

  const [planData1, setPlanData1] = useState([]);

  const loadNotification_set1 = () => {
    invokeServer('get', `/api/plans/find/2`)
      .then((r) => {
        const plans = r.data.msg;
        setPlanData1(plans);
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
  };

  const [planData2, setPlanData2] = useState([]);

  const loadNotification_set2 = () => {
    invokeServer('get', `/api/plans/find/3`)
      .then((r) => {
        const plans = r.data.msg;
        setPlanData2(plans);
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
  };

  const [planData3, setPlanData3] = useState([]);

  const loadNotification_set3 = () => {
    invokeServer('get', `/api/plans/find/4`)
      .then((r) => {
        const plans = r.data.msg;
        setPlanData3(plans);
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
  };

  const loadNotification1 = () => {
    invokeServer('get', `/api/plans/check/1`)
      .then((r) => {
        const plans = r.data.msg;
        setCost(plans.amount);
        setCycle(plans.amount * 3);
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
  };

  const loadNotification2 = () => {
    invokeServer('get', `/api/plans/check/2`)
      .then((r) => {
        const plans = r.data.msg;
        setCost2(plans.amount);
        setCycle2(plans.amount * 4);
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
  };

  const loadNotification3 = () => {
    invokeServer('get', `/api/plans/check/3`)
      .then((r) => {
        const plans = r.data.msg;
        setCost3(plans.amount);
        const level1 = (parseFloat(plans.amount) * 3 * 30) / 100;
        const level2 = (parseFloat(plans.amount) * 7 * 70) / 100;
        const final = level1 + level2;
        setCycle3(parseFloat(final.toFixed()) + parseFloat(8));
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
  };

  const loadNotification4 = () => {
    invokeServer('get', `/api/plans/check/4`)
      .then((r) => {
        const plans = r.data.msg;
        setCost4(plans.amount);
        const level1 = (parseFloat(plans.amount) * 4 * 20) / 100;
        const level2 = (parseFloat(plans.amount) * 8 * 30) / 100;
        const level3 = (parseFloat(plans.amount) * 14 * 50) / 100;
        const final = level1 + level2 + level3;
        setCycle4(final + 10);
      })
      .catch((err) => {
        console.log(`${err.message}`);
      });
  };

  function handleOne(one, two) {
    const newCost = one;
    const newCycle = two;

    setCost(newCost);
    setCycle(newCycle);
    document.getElementById('costNft').textContent = newCost;
    document.getElementById('cycleNft').textContent = newCycle;
  }

  function handleTwo(one, two) {
    const newCost2 = one;
    const newCycle2 = two;
    setCost2(newCost2);
    setCycle2(newCycle2);
    document.getElementById('costNft2').textContent = newCost2;
    document.getElementById('cycleNft2').textContent = newCycle2;
  }

  function handleThree(one, two) {
    const newCost3 = one;
    const newCycle3 = two;
    setCost3(newCost3);
    setCycle3(newCycle3);
    document.getElementById('costNft3').textContent = newCost3;
    document.getElementById('cycleNft3').textContent = newCycle3;
  }

  function handleFour(one, two) {
    const newCost4 = one;
    const newCycle4 = two;

    if (two == '1') {
      setCost4(10);
      setCycle4(112);
    }
    if (two == '2') {
      setCost4(30);
      setCycle4(306);
    }
    if (two == '3') {
      setCost4(60);
      setCycle4(612);
    }
    if (two == '4') {
      setCost4(110);
      setCycle4(1112);
    }
    if (two == '5') {
      setCost4(190);
      setCycle4(1938);
    }
    if (two == '6') {
      setCost4(320);
      setCycle4(3264);
    }
    if (two == '7') {
      setCost4(530);
      setCycle4(5406);
    }
    if (two == '8') {
      setCost4(870);
      setCycle4(8874);
    }
    if (two == '9') {
      setCost4(1420);
      setCycle4(14484);
    }
    if (two == '10') {
      setCost4(2310);
      setCycle4(23562);
    }
    if (two == '11') {
      setCost4(3750);
      setCycle4(38250);
    }
    if (two == '12') {
      setCost4(6080);
      setCycle4(62016);
    }
    if (two == '13') {
      setCost4(9850);
      setCycle4(100470);
    }
    if (two == '14') {
      setCost4(15950);
      setCycle4(162690);
    }
    if (two == '15') {
      setCost4(25820);
      setCycle4(263364);
    }

    // setCost4(newCost4);
    // setCycle4(newCycle4);

    // document.getElementById('costNft4').textContent = newCost4;
    // document.getElementById('cycleNft4').textContent = newCycle4;
  }

  const numberLists = document.querySelectorAll('.number-list');

  numberLists.forEach((numberList) => {
    const numberListItems = numberList.getElementsByTagName('li');

    for (let i = 0; i < numberListItems.length; i++) {
      numberListItems[i].addEventListener('click', function () {
        const clickedNumber = parseInt(this.innerHTML);

        if (this.classList.contains('active')) {
          for (let j = i + 1; j < numberListItems.length; j++) {
            numberListItems[j].classList.remove('active');
          }
        } else {
          for (let j = 0; j <= i; j++) {
            numberListItems[j].classList.add('active');
          }
        }
      });
    }
  });

  useEffect(() => {
    loadNotification1();
    loadNotification2();
    loadNotification3();
    loadNotification4();
    loadNotification();
  }, []);

  return (
    <>
      <NftCal>
        <h2>ZiMax Participant</h2>
        <h3>Calculator</h3>
        <p>
          Calculate your potential result from participating in ZiMax by
          selecting NFT levels to activate below. The results are calculated for
          1 cycle of all selected levels. All calculations are for informational
          purposes only, and are not a public offer.
        </p>
      </NftCal>
      <NftGrid>
        <div className="nftGridItem">
          <div className="nftGridItemHeading">
            <h2>
              <span>Z3</span> ZiMax
            </h2>
            <p>
              Basic NFT level program, which is best for those who are
              self-reliant and prefer independent development.
            </p>
          </div>
          <div className="nftGridBody">
            <ul className="number-list">
              {planData
                .filter((item) => item.plan_id === '1')
                .map((item, index) =>
                  index === 0 ? (
                    <li
                      onClick={() =>
                        handleOne(item.stage_fees, item.stage_fees * 3)
                      }
                      className="active"
                    >
                      {index + 1}
                    </li>
                  ) : (
                    <li
                      onClick={() =>
                        handleOne(item.stage_fees, item.stage_fees * 3)
                      }
                    >
                      {index + 1}
                    </li>
                  )
                )}
            </ul>

            <div className="nftInfo">
              <p>
                Cost of all selected slots{' '}
                <strong>
                  {' '}
                  <span id="costNft">{cost}</span> USDT
                </strong>
              </p>
              <h2>
                Results in 1 cycle{' '}
                <strong>
                  <span id="cycleNft">{cycle}</span> USDT
                </strong>
              </h2>
            </div>
          </div>
        </div>
        <div className="nftGridItem">
          <div className="nftGridItemHeading">
            <h2>
              <span>Z4</span> ZiMax
            </h2>
            <p>
              More advanced program, designed for team work. Results are
              achieved here through direct partners, as well as through
              spillovers from other participants.
            </p>
          </div>
          <div className="nftGridBody">
            <ul className="number-list">
              {planData
                .filter((item) => item.plan_id === '2')
                .map((item, index) =>
                  index === 0 ? (
                    <li
                      onClick={() =>
                        handleTwo(item.stage_fees, item.stage_fees * 3)
                      }
                      className="active"
                    >
                      {index + 1}
                    </li>
                  ) : (
                    <li
                      onClick={() =>
                        handleTwo(item.stage_fees, item.stage_fees * 4)
                      }
                    >
                      {index + 1}
                    </li>
                  )
                )}
            </ul>
            <div className="nftInfo">
              <p>
                Cost of all selected slots{' '}
                <strong>
                  {' '}
                  <span id="costNft2">{cost2}</span> USDT
                </strong>
              </p>
              <h2>
                Results in 1 cycle{' '}
                <strong>
                  <span id="cycleNft2">{cycle2}</span> USDT
                </strong>
              </h2>
            </div>
          </div>
        </div>

        <div className="nftGridItem">
          <div className="nftGridItemHeading">
            <h2>
              <span>Zx</span> ZiMax
            </h2>
            <p>
              Program with enhanced team building and development opportunities.
            </p>
          </div>
          <div className="nftGridBody">
            <ul className="number-list">
              {planData
                .filter((item) => item.plan_id === '3')
                .map((item, index) => {
                  const level1 =
                    (parseFloat(item.stage_fees == 2 ? 8 : item.stage_fees) *
                      3 *
                      30) /
                    100;
                  const level2 = (parseFloat(item.stage_fees) * 7 * 70) / 100;
                  const final1 = level1 + level2;
                  let final = final1.toFixed();

                  if (index === 0) {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          handleThree(
                            item.stage_fees,
                            parseFloat(final) + parseFloat(item.stage_fees)
                          )
                        }
                        className="active"
                      >
                        {index + 1}
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          handleThree(
                            item.stage_fees,
                            parseFloat(final) + parseFloat(item.stage_fees)
                          )
                        }
                      >
                        {index + 1}
                      </li>
                    );
                  }
                })}
            </ul>
            <div className="nftInfo">
              <p>
                Cost of all selected slots{' '}
                <strong>
                  {' '}
                  <span id="costNft3">{cost3}</span> USDT
                </strong>
              </p>
              <h2>
                Results in 1 cycle{' '}
                <strong>
                  <span id="cycleNft3">{cycle3}</span> USDT
                </strong>
              </h2>
            </div>
          </div>
        </div>

        <div className="nftGridItem">
          <div className="nftGridItemHeading">
            <h2>
              <span>zGold</span> ZiMax
            </h2>
            <p>
              Exclusive program with ultimate opportunities for team work and
              developments.
            </p>
          </div>
          <div className="nftGridBody">
            <ul className="number-list">
              {planData
                .filter((item) => item.plan_id === '4')
                .map((item, index) => {
                  let level1 = (parseFloat(item.stage_fees) * 4 * 20) / 100;
                  let level2 = (parseFloat(item.stage_fees) * 8 * 30) / 100;
                  let level3 = (parseFloat(item.stage_fees) * 14 * 50) / 100;

                  let final1 = level1 + level2 + level3;
                  let final = final1.toFixed();
                  if (index === 0) {
                    return (
                      <li
                        key={index}
                        onClick={() => handleFour(item.stage_fees, index + 1)}
                        className="active"
                      >
                        {index + 1}
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          handleFour(
                            item.stage_fees,
                            parseFloat(index) + parseFloat(1)
                          )
                        }
                      >
                        {index + 1}
                      </li>
                    );
                  }
                })}
            </ul>
            <div className="nftInfo">
              <p>
                Cost of all selected slots{' '}
                <strong>
                  {' '}
                  <span id="costNft4">{cost4}</span> USDT
                </strong>
              </p>
              <h2>
                Results in 1 cycle{' '}
                <strong>
                  <span id="cycleNft4">{cycle4}</span> USDT
                </strong>
              </h2>
            </div>
          </div>
        </div>
      </NftGrid>
      <NftTotal>
        <h2>
          Total cost <strong>{cost + cost2 + cost3 + cost4} USDT</strong>
        </h2>
        <h2>
          Total profit{' '}
          <strong>
            {parseFloat(cycle) +
              parseFloat(cycle2) +
              parseFloat(cycle3) +
              parseFloat(cycle4)}{' '}
            USDT
          </strong>
        </h2>
      </NftTotal>
    </>
  );
}
