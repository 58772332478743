import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Main, MainList, Item, ItemContent } from './styles';

import DollarIcon from '../../assets/dollar-icon.png';

export default function LevelsAreaInfluencers() {
  const [affBtn, setAffBtn] = useState(true);

  const ziipButton = () => {
    setAffBtn(true);
  };
  const affliateButton = () => {
    setAffBtn(false);
  };
  const { t } = useTranslation();
  return (
    <Main>
      <h2>
        <strong>{t('Affiliate Program')}</strong>
      </h2>
      <MainList>
        <Item>
          <div className="tabMenu">
            <h3 onClick={ziipButton}>{t('ZIIP Income Program')}</h3>
            <h3 onClick={affliateButton}>{t('Affiliates')}</h3>
          </div>
          <ItemContent>
            {affBtn ? (
              <>
                <h4>{t('BASIC FACT ABOUT ZIIP')}</h4>
                <p>
                  {t(
                    'The ZiMax Influencers Income Program (ZIIP) is tailored for influencers, celebrities, and individuals with substantial followers and dedicated communities. ZiMax offers a comprehensive package empowering them to establish their independent Decentralized Web3 business. This includes a personalized website and domain name seamlessly linked to the main platform, providing full access to all programs, functions, features, and DeFi solutions supported by ZiMax technology.'
                  )}
                </p>

                <h4>{t('Mechanism of ZIIP')}</h4>

                <p>
                  {t(
                    "ZIIP is among the most popular income programs, allowing users to engage in an NFT marketing program and begin selling ZiMax's ready-made NFTs, earning from 300% up to 1120% of the value of each NFT sold in the cycle by participants. The program operates with $USDT, providing a win-win opportunity for both influencers and followers. Other products feature their own distribution models for income, ensuring a lucrative return from marketing and sales."
                  )}
                </p>
                <p>
                  {t(
                    "In the initial stage of the ZIIP program, users can participate in the Z3 NFT and Z4 marketing program for only $5 USDT or opt for higher-level NFT programs such as ZX for $8 USDT or Z Gold for $10 USDT. While the process for increasing income remains the same across all programs, profits will be higher, ranging from 300% up to 1120% based on each NFT's value and cycle."
                  )}
                </p>
                <p>{t('How to Join? ')}</p>
                <p>
                  {t(
                    'To get started, all you need is a landing page or a website with your preferred domain name. ZiMax seamlessly links your website to the main platform, granting you full access to all six products tailored for your market and followers. This allows you to kickstart your blockchain-based Web3 business without the need to invest in developing unique blockchain products yourself. Your primary task is to market your business to your community, and ZiMax handles the rest of the process.'
                  )}
                </p>

                <p>
                  {t(
                    'All 6 products are available for you and your followers:'
                  )}
                </p>

                <p>{t('Token sale')}</p>
                <p>{t('Lottery')}</p>
                <p>{t('Bank 15')}</p>
                <p>{t('NFT Marketplace')}</p>
                <p>{t('ZIIP program')}</p>
                <p>{t('ZiMax Bank')}</p>

                <p>{t('How to Start Your Business:')}</p>
                <p>
                  {t(
                    '1. Connect your wallet and become a holder of the ZMX token.'
                  )}
                </p>
                <p>
                  {t("2.	Join ZiMax's official Telegram community @ZiMaxdefi.")}
                </p>

                <p>
                  {t(
                    '3. Contact the administrator to initiate the partnership procedure.'
                  )}
                </p>

                <p>
                  {t(
                    'For additional information and details, please refer to the ZiMax Gitbook.'
                  )}
                </p>
              </>
            ) : (
              <>
                <h4>{t('ZIMAX.IO Cryptocurrency Affiliate Program')}</h4>
                <p>
                  {t(
                    'Unlock lifetime referral rewards through the ZIMAX.IO Affiliate Program – a premier cryptocurrency affiliate initiative that lets you earn with minimal effort.'
                  )}
                </p>

                <p>
                  {t(
                    'Every ZIMAX.IO customer has the opportunity to earn lifelong rewards by simply sharing a personal referral link. As a participant in the ZIMAX.IO Affiliate Program, you contribute to expanding the reach of the open digital economy. In return, you receive USDT bonuses directly credited to your ZIMAX.IO account.'
                  )}
                </p>

                <p>
                  {t(
                    'Getting started is easy, all you need is an account on ZIMAX.IO.'
                  )}
                </p>
                <p>
                  {t(
                    'Your personalized referral link can be found on the My Team in the dashboard, share it with friends, relatives, and colleagues to begin earning effortlessly.'
                  )}
                </p>
                <h4>{t('Unlimited Earnings Opportunities')}</h4>
                <p>
                  {t(
                    'There are no restrictions on the amount you can earn. Invite as many people as you like, the more individuals who create accounts and trade on ZIMAX.IO after clicking your referral link, the greater your payouts.'
                  )}
                </p>
                <h4>{t('High Commission Rates')}</h4>
                <p>
                  {t(
                    "We offer industry-leading affiliate commissions of 10% on token sales and 1.5% on NFT Marketplace Sales. And that's just the beginning. Enjoy instant rewards credited directly to your wallet."
                  )}
                </p>
                <h4>{t('Affiliate-Friendly Cookie Duration')}</h4>
                <p>
                  {t(
                    'Our affiliate cookies have an extended duration of 90 days, ensuring you receive the full return on your efforts.'
                  )}
                </p>
                <h4>{t('Various Promotional Materials')}</h4>
                <p>
                  {t(
                    'Access a diverse selection of creative promotional content regularly updated to enhance your marketing efforts.'
                  )}
                </p>
                <h4>{t(' Dedicated Affiliate Manager')}</h4>
                <p>
                  {t(
                    'Our committed affiliate managers are here to help you succeed. Count on our support as we work together towards your goals.'
                  )}
                </p>
              </>
            )}
          </ItemContent>
        </Item>
      </MainList>
    </Main>
  );
}
