import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Main } from './styles';
import DollarIcon from '../../assets/dollar-icon.png';

export default function LevelsArea() {
  const [affBtn, setAffBtn] = useState(true);

  const ziipButton = () => {
    setAffBtn(true);
  };
  const affliateButton = () => {
    setAffBtn(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <Main>
        <img src={DollarIcon} alt="dollar-icon" />
        <h2>
          <strong>{t('Affiliate Program')}</strong>
        </h2>
        <p>
          {t(
            "Bank15 has its Auto-Stake feature, also known as Buy-Hold-Earn. The process is simple, users purchase and hold SZMX tokens in their wallet, and the platform's smart contract automatically distributes rebase rewards as interest payments to users proportionally based on the total amount of $ZMX tokens held."
          )}
        </p>
        <p>
          {t(
            ' With an APY ranging from 27,70% up to 30,00%, ZiMax holders can earn an annual compound interest without any additional action.'
          )}
        </p>
      </Main>
    </>
  );
}
