import { Link } from 'react-router-dom';
import { MainArea } from './styles';
import React, { useEffect, useState } from 'react';
import { useGlobal } from '../../contexts/GlobalContext';
import ReactLoading from 'react-loading';

export default function NftMatrix() {
  const { invokeServer } = useGlobal();
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lodingpopup, setLodingpopup] = useState(true);
  const [history, setHistory] = useState([]);

  const [memberCount, setMemberCount] = useState(0);
  const [memberRecCount, setMemberRecCount] = useState(0);
  const [amountCount, setAmountCount] = useState(0);
  const [transCount, setTransCount] = useState(0);

  useEffect(() => {
    const loadNotification = () => {
      invokeServer('get', `/api/plans`)
        .then((r) => {
          const plans = r.data.msg;
          setPlans(plans);
          setLodingpopup(false);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(`${err.message}`);
        });
    };

    loadNotification();
  }, []);

  useEffect(() => {
    const loadNotification = () => {
      invokeServer('get', `/api/plans/entry_history`)
        .then((r) => {
          const historys = r.data.msg;
          setHistory(historys);
          setMemberCount(r.data.userCount);
          setTransCount(r.data.transferCount);
          setMemberRecCount(r.data.mr);
        })
        .catch((err) => {
          console.log(`${err.message}`);
        });
    };

    loadNotification();
  }, []);

  //****************** CREATE PLAN *********************************/
  const createPlan = async () => {
    try {
      const response = await invokeServer('get', '/api/register/plan_insert');
      console.log('response', response);
    } catch (error) {
      console.log(`${error.message}`);
    }
  };

  return (
    <MainArea>
      {
        //***************************************************************************************************************** LODING POPUP
        lodingpopup && (
          <div className="popup-area is_loading">
            <div className="popup-area-inner">
              <>
                <div className="isLodingtrue">
                  {isLoading && (
                    <ReactLoading
                      type={'spokes'}
                      color={'#ffffff'}
                      height={100}
                      width={100}
                    />
                  )}
                </div>
              </>
            </div>
          </div>
        )
      }

      <h2>ZiMax Programs</h2>
      <div className="list-area">
        {plans.map((plan) => (
          <div
            className={
              plan.plan_no === '1'
                ? 'list-item list-item-x3'
                : plan.plan_no === '2'
                ? 'list-item list-item-x4'
                : plan.plan_no === '3'
                ? 'list-item list-item-zzz'
                : plan.plan_no === '4'
                ? 'list-item list-item-xgold'
                : ''
            }
          >
            <h3>
              {plan.plan_name === 'zZz' ? 'zx' : plan.plan_name}{' '}
              <span>{plan.amount} USDT</span>
            </h3>
            <div className="list-item-inner">
              <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <a href={`/${plan.plan_name}`}>
                Preview
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up-right-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M0 8a8 8 0 1 0 16 0A8 8 0 0 0 0
                     8zm5.904 2.803a.5.5 0 1 1-.707-.707L9.293 6H6.525a.5.5 0 1 1 0-1H10.5a.5.5 0 0 1 
                     .5.5v3.975a.5.5 0 0 1-1 0V6.707l-4.096 4.096z"
                  />
                </svg>
              </a>
            </div>
          </div>
        ))}

        {/* <button className="btn-unnaked"  onClick={() => createPlan()}> Insert Plan</button> */}
      </div>

      <div className="footer-list">
        <h2>Platform recent activity</h2>
        <div className="footer-list-area">
          <div className="footer-list-left">
            <ul>
              {history &&
                history.map((historyss) => (
                  <li>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-wallet2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                      </svg>{' '}
                      <strong className="footer-list-id">
                        ID {historyss.userid}
                      </strong>{' '}
                      <strong>
                        + {historyss.amount} USDT {historyss.des}
                      </strong>
                    </span>{' '}
                    <span>
                      <a
                        href={`https://testnet.bscscan.com/tx/${historyss.hash_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                          />
                        </svg>
                      </a>{' '}
                      {historyss.date}
                    </span>
                  </li>
                ))}
            </ul>
          </div>

          <div className="footer-list-right">
            <div className="footer-right-item">
              <h4>
                Members total{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                </svg>
              </h4>
              <h5>{memberCount}</h5>
              {/* <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>{' '}
                1531
              </span> */}
            </div>
            <div className="footer-right-item">
              <h4>
                Members received, USDT
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                </svg>
              </h4>
              <h5>{memberRecCount}</h5>
              {/* <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>{' '}
                71 509.8
              </span> */}
            </div>
            <div className="footer-right-item">
              <h4>
                ZiMax USDT Contracts
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                </svg>
              </h4>
              <ul>
                <li>
                  <span>
                    <strong>0x407...6d1</strong>{' '}
                    <strong>
                      <a
                        href={`https://testnet.bscscan.com/address/0x4074180534cd61a0bfcc2fa90b4862fbd83f96d1`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-back"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z" />
                        </svg>
                      </a>
                    </strong>{' '}
                  </span>
                </li>
              </ul>
            </div>
            <div className="footer-right-item">
              <h4>Transactions made</h4>
              <h5>{transCount}</h5>
              {/* <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>{' '}
                4 092
              </span> */}
            </div>
            {/* <div className="footer-right-item">
              <h4>Turnover, USDT</h4>
              <h5>270 052 777.92</h5>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  />
                </svg>{' '}
                143 019.6
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </MainArea>
  );
}
